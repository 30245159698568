<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="运费模板">
            </el-page-header>
        </div>
        <div class="center-content">
            <div class="iq-card-body">
                <el-form ref="freightForm" :model="freightForm" label-width="150px" :rules="rules">
                    <el-form-item label="模板名称" prop="templateName">
                        <el-input class="w-18" v-model="freightForm.templateName"></el-input>
                    </el-form-item>
                    <el-form-item label="快递/物流" prop="expressCompany">
                        <el-select class="w-18" v-model="freightForm.expressCompany" placeholder="请选择">
                            <el-option label="圆通速递" value="圆通速递"></el-option>
                            <el-option label="中通快递" value="中通快递"></el-option>
                            <el-option label="顺丰速运" value="顺丰速运"></el-option>
                            <el-option label="京东快递" value="京东快递"></el-option>
                            <el-option label="申通快递" value="申通快递"></el-option>
                            <el-option label="中国邮政" value="中国邮政"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否启用" prop="isEnable">
                        <el-switch v-model="freightForm.isEnable"></el-switch>
                    </el-form-item>
                    <el-form-item label="配送区域" prop="regionArr">
                        <el-cascader class="w-18" :placeholder="placeholder" v-model="freightForm.regionArr"
                            ref="cascader" :options="RegionData" size="medium" collapse-tags :props="{
                                emitPath: false,
                                multiple: true,
                                checkStrictly: false,
                                lazy: true,
                                lazyLoad: LasyLoadRegion,
                                value: 'id',
                                label: 'regionName',
                                children: 'childrens',
                            }" @change="cascaderChange" style="margin-right: 10px; margin-bottom: 22px">
                        </el-cascader>
                        <div class="err-str" v-if="errStr">{{ errStr }}</div>
                    </el-form-item>
                    <el-form-item label="计费方式" prop="firstPrice">
                        <el-descriptions direction="vertical" :column="4" border style="width: 650px">
                            <el-descriptions-item label="首件">
                                <el-input value="1" disabled></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="首件运费">
                                <el-input v-model="freightForm.firstPrice"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="每增件数">
                                <el-input v-model="freightForm.addQty"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="续件运费">
                                <el-input v-model="freightForm.renewalPrice"></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="saveBtn('freightForm')">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Region } from "../../../components/HospitalDomain/Region";
import { Freight } from "../../../components/HospitalDomain/ShopMall/Freight";

export default {
    name: "FreightAdd",
    components: {
        bread
    },
    data() {
        var region = new Region(this.TokenClient, this.Services.Authorization);
        var freight = new Freight(this.TokenClient, this.Services.Shopping);
        return {
            FreightDomain: freight,
            Region: region,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            freightForm: {
                id: this.$route.query.id,
                isEnable: false,
                regionArr: []
            },
            RegionData: [],
            rules: {
                templateName: [
                    { required: true, message: '请输入模板名称', trigger: 'blur' },
                ],
                expressCompany: [
                    { required: true, message: '请选择快递/物流', trigger: 'change' }
                ],
                firstPrice: [
                    { required: true, message: '请输入计费方式', trigger: 'blur' },
                    { pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: '请输入正确格式的金额', trigger: 'blur' }
                ],
                addQty: [
                    { required: true, message: '请输入每增件数', trigger: 'blur' },
                ],
                renewalPrice: [
                    { required: true, message: '请输入续件运费', trigger: 'blur' },
                    { pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: '请输入正确格式的金额', trigger: 'blur' }
                ]
            },
            errStr: ""
        }
    },
    computed: {
        placeholder() {
            var regionArr = this.freightForm.regionArr
            return regionArr.join()
        }
    },
    created() {
        if (this.freightForm.id > 0) {
            this.getDetail()
        }
    },
    mounted() {
        this.InitialRegion();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
            this.$refs.freightForm.resetFields();
        },
        InitialRegion() {
            var _this = this;
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 2;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                    console.log(err);
                });
        },
        cascaderChange() {
            let nodesInfo = this.$refs['cascader'].getCheckedNodes()
            var _this = this;
            _this.freightForm.regions = [];
            nodesInfo.forEach((item, i) => {
                _this.freightForm.regions.push({
                    regionId: item.value,
                    regionName: item.label,
                    regionCode: item.data.regionCode,
                })
            });
            this.errStr = ""
            if (this.freightForm.regionArr.length == 0) {
                this.errStr = "请选择配送区域"
                return;
            }
        },
        getDetail() {
            var _this = this;
            _this.FreightDomain.FreightDetail(_this.freightForm.id,
                function (data) {
                    _this.freightForm = data.data;
                    _this.freightForm.regionArr = [];
                    var Arr = data.data.regions
                    Arr.forEach((item, i) => {
                        _this.freightForm.regionArr.push(item.regionName)
                    });
                },
                function (err) {
                    console.log(err);
                });
        },
        saveBtn(formName) {
            var _this = this;
            this.errStr = ""
            if (this.freightForm.regionArr.length == 0) {
                this.errStr = "请选择配送区域"
                return;
            }
            var params = {
                id: _this.freightForm.id,
                organizationId: _this.organizationId,
                templateName: _this.freightForm.templateName,
                firstPrice: _this.freightForm.firstPrice,
                addQty: _this.freightForm.addQty,
                renewalPrice: _this.freightForm.renewalPrice,
                isEnable: _this.freightForm.isEnable,
                expressCompany: _this.freightForm.expressCompany,
                regions: _this.freightForm.regions
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.freightForm.id > 0) {
                        _this.FreightDomain.EditFreight(params, function (data) {
                            _this.$message({
                                type: 'success',
                                message: '修改成功!'
                            });
                            _this.$refs[formName].resetFields();
                            _this.$router.go(-1);
                        }, function (err) { console.log(err); });
                    } else {
                        _this.FreightDomain.AddFreight(params, function (data) {
                            _this.$message({
                                type: 'success',
                                message: '添加成功!'
                            });
                            _this.$refs[formName].resetFields();
                            _this.$router.go(-1);
                        }, function (err) { console.log(err); });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>

.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}

/*content*/


.err-str {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    /* padding-top: 4px; */
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -17px;
}
</style>
