var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"height1"},[_c('div',{staticClass:"iq-top-navbar"},[_c('el-page-header',{attrs:{"content":"运费模板"},on:{"back":_vm.goBack}})],1),_c('div',{staticClass:"center-content"},[_c('div',{staticClass:"iq-card-body"},[_c('el-form',{ref:"freightForm",attrs:{"model":_vm.freightForm,"label-width":"150px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"模板名称","prop":"templateName"}},[_c('el-input',{staticClass:"w-18",model:{value:(_vm.freightForm.templateName),callback:function ($$v) {_vm.$set(_vm.freightForm, "templateName", $$v)},expression:"freightForm.templateName"}})],1),_c('el-form-item',{attrs:{"label":"快递/物流","prop":"expressCompany"}},[_c('el-select',{staticClass:"w-18",attrs:{"placeholder":"请选择"},model:{value:(_vm.freightForm.expressCompany),callback:function ($$v) {_vm.$set(_vm.freightForm, "expressCompany", $$v)},expression:"freightForm.expressCompany"}},[_c('el-option',{attrs:{"label":"圆通速递","value":"圆通速递"}}),_c('el-option',{attrs:{"label":"中通快递","value":"中通快递"}}),_c('el-option',{attrs:{"label":"顺丰速运","value":"顺丰速运"}}),_c('el-option',{attrs:{"label":"京东快递","value":"京东快递"}}),_c('el-option',{attrs:{"label":"申通快递","value":"申通快递"}}),_c('el-option',{attrs:{"label":"中国邮政","value":"中国邮政"}})],1)],1),_c('el-form-item',{attrs:{"label":"是否启用","prop":"isEnable"}},[_c('el-switch',{model:{value:(_vm.freightForm.isEnable),callback:function ($$v) {_vm.$set(_vm.freightForm, "isEnable", $$v)},expression:"freightForm.isEnable"}})],1),_c('el-form-item',{attrs:{"label":"配送区域","prop":"regionArr"}},[_c('el-cascader',{ref:"cascader",staticClass:"w-18",staticStyle:{"margin-right":"10px","margin-bottom":"22px"},attrs:{"placeholder":_vm.placeholder,"options":_vm.RegionData,"size":"medium","collapse-tags":"","props":{
                            emitPath: false,
                            multiple: true,
                            checkStrictly: false,
                            lazy: true,
                            lazyLoad: _vm.LasyLoadRegion,
                            value: 'id',
                            label: 'regionName',
                            children: 'childrens',
                        }},on:{"change":_vm.cascaderChange},model:{value:(_vm.freightForm.regionArr),callback:function ($$v) {_vm.$set(_vm.freightForm, "regionArr", $$v)},expression:"freightForm.regionArr"}}),(_vm.errStr)?_c('div',{staticClass:"err-str"},[_vm._v(_vm._s(_vm.errStr))]):_vm._e()],1),_c('el-form-item',{attrs:{"label":"计费方式","prop":"firstPrice"}},[_c('el-descriptions',{staticStyle:{"width":"650px"},attrs:{"direction":"vertical","column":4,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"首件"}},[_c('el-input',{attrs:{"value":"1","disabled":""}})],1),_c('el-descriptions-item',{attrs:{"label":"首件运费"}},[_c('el-input',{model:{value:(_vm.freightForm.firstPrice),callback:function ($$v) {_vm.$set(_vm.freightForm, "firstPrice", $$v)},expression:"freightForm.firstPrice"}})],1),_c('el-descriptions-item',{attrs:{"label":"每增件数"}},[_c('el-input',{model:{value:(_vm.freightForm.addQty),callback:function ($$v) {_vm.$set(_vm.freightForm, "addQty", $$v)},expression:"freightForm.addQty"}})],1),_c('el-descriptions-item',{attrs:{"label":"续件运费"}},[_c('el-input',{model:{value:(_vm.freightForm.renewalPrice),callback:function ($$v) {_vm.$set(_vm.freightForm, "renewalPrice", $$v)},expression:"freightForm.renewalPrice"}})],1)],1)],1),_c('el-form-item',[_c('el-button',{on:{"click":function($event){return _vm.saveBtn('freightForm')}}},[_vm._v("保存")])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }